import React, { Component } from 'react';
import Header from '../components/header';
import Canvas from '../components/canvas';

export default class extends Component {
  
  render() {
    return (
      <Canvas>
        <Header/>
        <div className="main-description">
          <div className="hero-content prime-color">
            <h1 className="typewriter prime-color text-center">
              R Aswath Prabhu
            </h1>
            <p>Software Developer | Javascript aficionado | Open Source Enthusiast</p>
          </div>
        </div>
      </Canvas>
    );
  }
}