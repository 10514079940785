import React, { Component } from 'react';
import { Link } from 'gatsby'
import Techionado from '../images/Techionado.png';

const Header = (
    <nav className="nav">
      <div className="align-nav fade-down text-center">
        <ul>
          <li className="cursor-pointer prime-color"><Link to="/home/" className="prime-color">Home</Link></li>
          <li className="cursor-pointer prime-color"><Link to="/blog/" className="prime-color">Blogs</Link></li>
          {/* <li className="cursor-pointer prime-color"><Link to="/about/" className="prime-color">About</Link></li> */}
        </ul>
      </div>
    </nav>
  );

export default class extends Component {
  state = {
    showNav: false
  }

  toggleShowNav = () => {
    let showNav = this.state.showNav;
    this.setState({
      showNav: !showNav
    })
  }

  styleHamburger = ['hamburger', 'hamburger--emphatic']
  
  handleHamburger = () => {
    if (this.styleHamburger.includes('is-active')) {
      this.styleHamburger.pop();
    } else {
      this.styleHamburger.push('is-active');
    }
    this.toggleShowNav();
  }

  render() {
    let renderingModules = [
      <div className="align-header">
        <div>
          <img src={Techionado} alt="Techionado" width="250" /> 
        </div>
        <button className={this.styleHamburger.join(' ')} onClick={this.handleHamburger}>
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </button>
      </div>
    ];
    
    if(this.state.showNav) {
      renderingModules.push(Header);
    }

    return (
      <>
        {renderingModules.map((module, id) => <React.Fragment key={id}>{module}</React.Fragment>)}
      </>
    );
  }
}